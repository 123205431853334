import { createSlice } from "@reduxjs/toolkit";
import { NUMBER_OF_IMAGES } from "../../utils/constant";

interface EventState {
  typeModel: string;
  typeName: string;
  templateName: string;
  templateCode: string;
  prompt: string;
  negativePrompt: string;
  samplingStep: number;
  denoisingStrength: number;
  controlWeight: number;
  BGWeight: number;
  selectedItems: any;
  numberOfImages: number;
  isClickCopySetting: boolean;
  poseId: string;
  isClickControlWeight: boolean;
  isClickBGWeight: boolean;
  segment: string[];
  annotations: any[];
  originImageUrl: string;
  samSpecialId: string;
  poseData: number[];
  poseImage: string;
  width: number;
  height: number;
  backgroundImage: string;
}

const initialState: EventState = {
  typeModel: "",
  typeName: "",
  templateName: "",
  templateCode: "",
  prompt: "",
  negativePrompt: "",
  samplingStep: 20,
  denoisingStrength: 0.75,
  controlWeight: 1,
  BGWeight: 1,
  selectedItems: [],
  numberOfImages: NUMBER_OF_IMAGES,
  isClickCopySetting: false,
  poseId: "",
  isClickControlWeight: false,
  isClickBGWeight: false,
  segment: [],
  annotations: [],
  originImageUrl: "",
  samSpecialId: "",
  poseData: [],
  poseImage: "",
  width: 0,
  height: 0,
  backgroundImage: "",
};

const eventSelect = createSlice({
  name: "selectTypeModel",
  initialState,
  reducers: {
    setTypeModel: (state, action) => {
      state.typeModel = action.payload;
    },
    setNumberOfImages: (state, action) => {
      state.numberOfImages = action.payload;
    },
    setTypeName: (state, action) => {
      state.typeName = action.payload;
    },
    setOriginImageUrl: (state, action) => {
      state.originImageUrl = action.payload;
    },
    setFashionParams: (state, action) => {
      state.templateName = action.payload.templateName;
      state.templateCode = action.payload.templateCode;
      state.prompt = action.payload.prompt;
      state.negativePrompt = action.payload.negativePrompt;
      state.samplingStep = action.payload.samplingStep;
      state.denoisingStrength = action.payload.denoisingStrength;
      state.controlWeight = action.payload.controlWeight;
      state.numberOfImages = action.payload.numberOfImages;
      state.isClickCopySetting = action.payload.isClickCopySetting;
      state.poseId = action.payload.poseId;
      state.isClickBGWeight = action.payload.isClickBGWeight;
      state.isClickControlWeight = action.payload.isClickControlWeight;
      state.selectedItems = action.payload.selectedItems;
      state.segment = action.payload.segment;
      state.annotations = action.payload.annotations;
      state.samSpecialId = action.payload.samSpecialId;
      state.poseData = action.payload.poseData;
      state.poseImage = action.payload.poseImage;
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.backgroundImage = action.payload.backgroundImage;
      state.BGWeight = action.payload.BGWeight;
    },
  },
});

export const setTypeModel = eventSelect.actions;
export default eventSelect.reducer;
