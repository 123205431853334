import { Button, Input, Radio, RadioChangeEvent, Slider, Space, Tooltip } from 'antd';
import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DragOutlined, HighlightOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined
} from '@ant-design/icons';

import redo from '../../assets/icons/redo.svg';
import reset from '../../assets/icons/reset.svg';
import undo from '../../assets/icons/undo.svg';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { useDebouncedCallback } from '../../redux/hooks/useDebouncedCallback';
import FashionService from '../../services/fashion.service';
import { WhitelistFeatureEnum, WhitelistTypeEnum } from '../../utils/enum';
import { getDrawCursor, getImageSize, scaleImg, urlToBase64 } from '../../utils/function';
import { IFeatureWhitelist } from '../../utils/interface';
import NotifyController from '../../utils/toast';
import ModalRequiredSub from '../ModalRequiredSub/ModalRequiredSub';
import PopupAddToWhiteList from '../PopupAddToWhiteList';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userSaga } from '../../redux/app/authSaga';
import Heading from '../../pages/Editor/DetailsTools/Heading';
import { setLoadingApp } from '../../redux/app/loadingApp';
import { editorImage as ImageEditor } from '../../redux/app/appImagePreprocessing';
import swal from 'sweetalert';
import { toolEditorImage } from '../../redux/app/toolEditor';
import NewModalRequiredSub from '../ModalRequiredSub/NewModalRequiredSub';
import { ModalSub } from '../../redux/app/appModalSub';
import AccountService from '../../services/account.service';

type ModalRemoveObjectProps = {
    url: string,
    imageId: string,
    onClickSave: () => void,
    onClickBackToHome: () => void,
    isModal: boolean,
    setIsOpenModal: Function,
    setIsGenerating: Function,
}

const typePermissionRemoveObject = {
    feature: WhitelistFeatureEnum.REMOVE_OBJECT,
    type: WhitelistTypeEnum.FEATURE
}

const typePermissionRemoveRedundantDetail = {
    feature: WhitelistFeatureEnum.REMOVE_REDUNDANT_DETAIL,
    type: WhitelistTypeEnum.FEATURE
}

const typePermissionReGenerate = {
    feature: WhitelistFeatureEnum.CORRECT_PART,
    type: WhitelistTypeEnum.FEATURE
}

function ModalRemoveObject({ url, imageId, onClickSave, onClickBackToHome, isModal, setIsOpenModal, setIsGenerating }: ModalRemoveObjectProps) {
    const { t } = useTranslation()
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasLayerTop = new fabric.Canvas("layerTop");
    const [warning, setWarning] = useState<boolean>(false);
    const dataCost = useAppSelector(store => store.costFeature)
    const permissionRemoveObject = useAppSelector((store) => store.user.isOpeningRemoveObject);
    const permissionRemoveRedundantDetail = useAppSelector((store) => store.user.isOpeningRemoveRedundant);
    const permissionCorrectWrongPart = useAppSelector((store) => store.user.isOpeningCorrectPart);
    const blackWordsRedux = useAppSelector((store) => store.costFeature.blackWords);
    const coins = useAppSelector((store) => store.user.coins)
    const editorImage = useAppSelector((store) => store.EditorImage);

    const [specialId, setSpecialId] = useState('');
    const [disableGenButton, setDisableGenButton] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [ready, setReady] = useState(false);
    const [widthPoint, setWidthPoint] = useState<any>(10)
    const [widthPointByZoom, setWidthPointByZoom] = useState<any>(10);
    const [ratio, setRatio] = useState<number>(1)
    const [valueZoom, setValueZoom] = useState<any>(100)
    const [modalSub, setModalSub] = useState(false)
    const [isRightMouseDown, setRightMouseDown] = useState(false);
    const [isOpenModalWhiteList, setIsOpenModalWhiteList] = useState(false);
    const [isDragging, setDragging] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [mode, setMode] = useState('correct_wrong_part');
    const [modeMove, setModeMove] = useState('brush');
    const [typePermission, setTypePermission] = useState<IFeatureWhitelist>(typePermissionRemoveObject)
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();

    const dispatch = useDispatch()

    const fillAll = async (arrayPath: any) => {
        try {
            setDisableGenButton(true);
            dispatch(setLoadingApp.setLoading(true))
            const canvas = new fabric.Canvas('layerTop')
            canvasLayerTop.setWidth(canvasSizeData?.widthCanvas as number)
            canvasLayerTop.setHeight(canvasSizeData?.widthCanvas as number / ratio);
            canvasLayerTop.setBackgroundColor('black', () => { })
            let path: any = []
            arrayPath.map((value: any, idx: number) => {
                path.push(drawLine(value.path, value.left, value.top))
            })
            path.map((e: any) => canvasLayerTop.add(e))
            canvas.renderAll();
            const stringUrl = canvasLayerTop.toDataURL({
                multiplier: multiplier
            });
            const urlBase64 = await urlToBase64(url);
            let RemovedId: string = '';
            if (mode === 'remove_redundant_detail') {
                if (permissionRemoveRedundantDetail) {
                    RemovedId = await FashionService.getInstance().processRemoveRedundantDetail({
                        initImage: urlBase64,
                        mask: stringUrl,
                        id: imageId
                    })
                    dispatch(userSaga.setCredits(coins - dataCost.removeObject));
                } else {
                    setTypePermission(typePermissionRemoveRedundantDetail)
                    setIsOpenModalWhiteList(true)
                }
            }
            if (mode === 'remove_object') {
                if (permissionRemoveObject) {
                    RemovedId = await FashionService.getInstance().processRemoveObject({
                        initImage: urlBase64,
                        mask: stringUrl,
                        id: imageId
                    })
                } else {
                    setTypePermission(typePermissionRemoveObject)
                    setIsOpenModalWhiteList(true)
                }
            }
            if (mode === 'correct_wrong_part') {
                if (permissionCorrectWrongPart) {
                    RemovedId = await FashionService.getInstance().processCorrectPart({
                        initImage: urlBase64,
                        mask: stringUrl,
                        id: imageId,
                        prompt
                    })
                } else {
                    setTypePermission(typePermissionReGenerate)
                    setIsOpenModalWhiteList(true)
                }
            }
            setSpecialId(RemovedId);
            setDisableGenButton(false);
        } catch (error: any) {
            console.log(error)
            dispatch(setLoadingApp.setLoading(false))
            setDisableGenButton(false);
            swal(t(error.message), '', 'error')
        }
    }

    const drawLine = (arrayPath: any, x: number, y: number) => {
        function convertArrayToPathString(array: any) {
            let pathString = '';
            array.forEach((segment: any) => {
                pathString += segment.shift() + ' ' + segment.join(' ') + ' ';
            });
            return pathString.trim();
        }
        const stringPath = convertArrayToPathString(arrayPath)
        const path = new fabric.Path(stringPath, {
            stroke: 'white',
            strokeWidth: widthPoint,
            fill: 'transparent',
            left: x,
            top: y,
            selectable: false,
            backgroundColor: 'transparent',
            strokeLineCap: 'round'
        });
        return path
    }

    const onOk = async () => {
        if (coins < dataCost.removeObject) {
            dispatch(ModalSub.setIsOpenModalSub(true))
            NotifyController.warning(t('Not_enough_credit'))
            return;
        } else {
            if (fabricRef.current) {
                if (fabricRef.current._objects.length <= 0) {
                    NotifyController.warning(t('please_select_mask'))
                } else {
                    fillAll(fabricRef.current._objects);
                }
            }
        }
    }

    const inputOpacityPoint = (e: any) => {
        setWidthPoint(e)
    }

    const handleMinus = (e: any) => {
        const valueInput = widthPoint - 1
        setWidthPoint(valueInput)
    }

    const handlePlus = (e: any) => {
        const valueInput = widthPoint + 1
        setWidthPoint(valueInput)
    }

    let isRedoing = false;
    let h: any = [];

    const handleUndo = () => {
        if (fabricRef.current && fabricRef.current._objects.length > 0) {
            h.push(fabricRef.current && fabricRef.current._objects.pop());
            fabricRef.current && fabricRef.current.renderAll();
        }
    }

    const handleRedo = () => {
        if (h.length > 0) {
            isRedoing = true;
            fabricRef.current && fabricRef.current.add(h.pop());
        }
    }

    const handleReset = () => {
        isRedoing = true
        h = [];
        if (fabricRef.current) {
            fabricRef.current._objects = []
            fabricRef.current && fabricRef.current.renderAll();
            setValueZoom(100)
            setWidthPoint(10)
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
    }

    const setBg = (canvasSizeData: any) => {
        return new Promise<void>((resolve) => {
            fabric.Image.fromURL(
                url || "",
                function (img: any) {
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: canvasSizeData.scaleX,
                                scaleY: canvasSizeData.scaleY,
                            }
                        );
                    ratio > 0 &&
                        fabricRef &&
                        fabricRef.current?.setHeight(
                            canvasSizeData.widthCanvas / ratio || 300
                        );

                    fabricRef &&
                        fabricRef.current?.setWidth(canvasSizeData.widthCanvas || 400);
                    resolve();
                },
                { crossOrigin: "*" }
            );
        })
    };

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    function disablePanning() {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.off('mouse:move');
        }
    }

    const handleContextMenu = (event: any) => {
        if (event.button === 2) {
            disablePanning()
            setRightMouseDown(true);
            event.preventDefault();
        }
    };

    const handleMouseDown = (event: any) => {
        if (event.button != 2) return
        if (isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = 'grab'
            setDragging(true);
        }
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`
            const delta = new fabric.Point(event.movementX, event.movementY);
            fabricRef.current.relativePan(delta);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && fabricRef.current) {
            setDragging(false);
            modeMove === 'move' && setModeMove('brush')
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
        setRightMouseDown(false);
    };

    let panning = false;
    const onChangeSwitch = (value: string) => {
        const checked = value === 'move';
        setModeMove(value);
        if (fabricRef.current && checked) {
            fabricRef.current.selection = false;
            fabricRef.current.isDrawingMode = false;
            // fabricRef.current.renderAll();
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
            });
            fabricRef.current.on('mouse:up', function (e) {
                panning = false;
            });

            fabricRef.current.on('mouse:down', function (e) {
                panning = true;
            });

            let previousTouch: any;
            checked && fabricRef.current.on('mouse:move', function (e: any) {
                if (checked && panning && e && e.e && fabricRef.current) {
                    if (e.e.type === 'mousemove') {
                        const delta = new fabric.Point(e.e.movementX, e.e.movementY);
                        fabricRef.current.relativePan(delta);
                    }
                    if (e.e.type === 'touchmove' && panning) {
                        const touch = e.e?.touches[0];
                        if (previousTouch) {
                            const mX = touch.pageX - previousTouch?.pageX;
                            const mY = touch.pageY - previousTouch?.pageY;
                            const delta = new fabric.Point(mX, mY);
                            fabricRef.current.relativePan(delta);
                        }
                        previousTouch = touch;
                    }
                }
            });
        }
        if (!checked && fabricRef.current) {
            disablePanning()
        }
    };

    const onChangeMode = (e: RadioChangeEvent) => {
        setMode(e.target.value);
    };
    const updateUserCredits = async () => {
        try {
            const { coins } = await AccountService.getInstance().getUserInfo();
            dispatch(userSaga.setCredits(coins));
        } catch (error) {
            swal(t('something_wrong'), "", "error");
        }
    }
    const initialSetStateBG = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImg(width && width, height && height);
        if (imgScale) {

            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }
    }, [url]);

    const handleResize = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImg(width && width, height && height);
        if (imgScale) {
            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }

    }, [url])

    const initFabric = useCallback(() => {
        if (canvasRef.current) {
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                isDrawingMode: true,
            });
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(
                widthPoint
            )}) 5 5, crosshair`;
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.freeDrawingBrush.color = "rgba(0, 71, 255, 0.5)";
            fabricRef.current.on("object:added", function () {
                if (!isRedoing) {
                    h = [];
                }
                isRedoing = false;
            });
            fabricRef.current.on("mouse:wheel", function (opt: any) {
                if (fabricRef.current) {
                    const delta = opt.e.deltaY;
                    let zoom = fabricRef.current.getZoom();
                    zoom *= 0.999 ** delta;
                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;
                    const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                    fabricRef.current.zoomToPoint(zoomCenter, zoom);
                }
            });
        }
    }, [canvasRef, fabricRef, canvasSizeData]);

    useEffect(() => {
        (async () => {
            if (canvasSizeData) {
                await setBg(canvasSizeData);
            }
        })();

    }, [canvasSizeData])

    useEffect(() => {
        if (ready) {
            (async () => {
                if (!fabricRef.current) {
                    initFabric();
                }

            })();
        }
        return () => {
            timeoutId && clearTimeout(timeoutId);
        };
    }, [ready, initFabric, canvasSizeData]);

    const resizeHandler = useDebouncedCallback(async () => {
        handleReset();
        await handleResize();
    }, 50);

    useEffect(() => {
        if (!ready) {
            (async () => {
                await initialSetStateBG();
                await handleResize();
                setReady(true);
            })();
        }
    }, [ready, initialSetStateBG, handleResize]);

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [resizeHandler]);

    useEffect(() => {
        setWidthPointByZoom(Math.round(widthPoint / (valueZoom / 100)))
    }, [valueZoom, widthPoint])

    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPointByZoom;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
    }, [widthPointByZoom])

    useEffect(() => { setRightMouseDown(true); }, [isRightMouseDown])
    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100)
        setValueZoom(e)
    }

    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPoint;
            modeMove === 'brush' && (fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`);
        }
    }, [widthPoint])

    let interval: any;
    useEffect(() => {
        if (specialId) {

            interval = setInterval(async () => {
                const listeningImage = await FashionService.getInstance().listenImageRemove(specialId);
                if (listeningImage.status === 'FINISHED') {
                    updateUserCredits()
                    if (interval) {
                        clearInterval(interval);
                    }
                    const urlBase64 = await urlToBase64(listeningImage.url_arr[0] + '?not-from-cache-please');
                    const imageUrl = 'data:image/png;base64,' + urlBase64;
                    dispatch(toolEditorImage.setImageInput(imageUrl))
                    dispatch(toolEditorImage.setSizeImage({
                        width: editorImage.width,
                        height: editorImage.height
                    }))
                    dispatch(ImageEditor.setImageEditor({ ...editorImage, imageUrl }))
                    dispatch(setLoadingApp.setLoading(false))
                    setReady(false);
                    handleReset();
                }
                if (listeningImage.status === 'FAILED') {
                    if (interval) {
                        clearInterval(interval);
                    }
                    swal(t('something_wrong'), "", "error");
                    updateUserCredits()
                    handleReset()
                    dispatch(setLoadingApp.setLoading(false))
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [specialId])
    const blackwords = blackWordsRedux && blackWordsRedux.split(",") || []; // list blackword
    let timeoutId: any
    const onChangePrompt = (e: any) => {
        timeoutId && clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            const value = e.target.value.toLowerCase();
            setPrompt(value);
            const nsfwRegex = new RegExp(`(?:${blackwords.join("|")})|[^a-zA-Z0-9 ]`, "gi");
            const isWarning = nsfwRegex.test(value.replace(/\s+/g, ''))
            setWarning(isWarning);
            setDisableGenButton(isWarning)
        }, 500);

    }
    return (
        <div className='flex flex-col gap-2'>
            <div>
                <Heading title={t('regen')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            </div>

            {isOpenModalWhiteList && <PopupAddToWhiteList isShowFormWhiteList={isOpenModalWhiteList} setIsShowFormWhiteList={setIsOpenModalWhiteList} setPermission={typePermission} />}
            {/* <Modal open={isOpen} onCancel={onCancel} width={'fit-content'} title={t('area_regeneration')} onOk={onOk} okText={
                <div>
                    <span>{t('confirm')} </span><span className='ml-1'> (-{FeatureCostEnum.REMOVE_OBJECT}</span><ThunderboltOutlined style={{ fontSize: 12, margin: 0 }} /><span className='!ml-0'>)</span>

                </div>
            }
                cancelText={t('cancel')}
            > */}
            <div className='modal-section flex !flex-row gap-4 justify-between  items-start sm:items-center md:items-center   w-full sm:!flex-col md:!flex-col lg:!flex-col '>
                <div className='right flex flex-col gap-4 ml-10 pb-3'>
                    {/* <hr className="bg-[#F5F5F5] h-[1px] border-none " /> */}
                    <div className='sub-title text-base  font-semibold text-left !mt-0'>{t('action')}</div>
                    <div className='container-button flex justify-between'>
                        <div className='box-button flex gap-2'>
                            <div className='undo hover:cursor-pointer' onClick={handleUndo}>
                                <img src={undo} alt="" />
                            </div>
                            <div className='redo hover:cursor-pointer' onClick={handleRedo}>
                                <img src={redo} alt="" />
                            </div>
                        </div>
                        <div className='reset flex gap-2'>
                            <div className='hover:cursor-pointer' onClick={handleReset}>
                                <img src={reset} alt="" />
                            </div>
                            <div className='text-[#667085]'>{t('Reset')}</div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 mt-2'>
                        <div>
                            <div className='text-left'>
                                {t('size_brush')}:
                            </div>
                        </div>
                        <div className='flex gap-2 max-w-[400px]'>
                            <Button
                                shape="circle"
                                type="default"
                                icon={<MinusOutlined />}
                                onClick={e => handleMinus(e)}
                            />
                            <Slider min={1} max={100} value={widthPoint} onChange={e => inputOpacityPoint(e)} className='min-w-[210px]' />
                            <Button
                                shape="circle"
                                type="default"
                                icon={<PlusOutlined />}
                                onClick={e => handlePlus(e)}
                            />
                        </div>

                    </div>
                    <div className=' flex flex-col flex-2'>
                        <div>
                            <div className='text-left'>
                                {t('zoom_image')}:
                            </div>
                        </div>
                        <div className='flex gap-2  sm:flex-col'>
                            <div className='flex gap-2 justify-center items-center'>
                                <div>100%</div>
                                <Slider min={100} max={300} defaultValue={100} value={valueZoom} onChange={onChangeZoomPlus} className='min-w-[210px]' />
                                <div>300%</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div>
                            {t('mode')} :
                        </div>
                        <div className='text-left' >
                            <Radio.Group defaultValue={'correct_wrong_part'} onChange={onChangeMode} >
                                <Space direction="vertical">
                                    <Radio value={'correct_wrong_part'}>{t('correct_wrong_part')}
                                        <Tooltip title={t('tooltip_regen')} ><InfoCircleOutlined className='ml-1' /> </Tooltip>
                                    </Radio>
                                    <Radio value={'remove_redundant_detail'}>{t('remove_redundant_detail')}
                                        <Tooltip title={t('tooltip_remove_redundant_detail')} ><InfoCircleOutlined className='ml-1' /> </Tooltip>
                                    </Radio>
                                    <Radio value={'remove_object'}>{t('remove_object')}
                                        <Tooltip title={t('tooltip_remove_object')} ><InfoCircleOutlined className='ml-1' /> </Tooltip>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                    {mode === 'correct_wrong_part' &&
                        <div className='flex flex-col gap-1'>
                            <div className='text-left'>Prompt    <Tooltip title={t('info_prompt')} ><InfoCircleOutlined className='ml-1' /> </Tooltip></div>
                            <Input placeholder={t('example_prompt_placeholder')} onChange={e => onChangePrompt(e)} />
                            {warning && <p className='text-[red] text-left' >{t('prompt_contain_bad_word')}</p>}
                        </div>
                    }
                    <div className='sub-title text-base  font-semibold text-left mt-4'>{t('guide')}</div>
                    <div className='tip  flex gap-2 rounded-lg max-w-[400px] p-3 text-left box-guide-tool'>
                        <ul className='text-[#667085] text-sm ml-3'>
                            <li >{t('remove_guide_1')}</li>
                            <li >{t('remove_guide_2')}</li>
                        </ul>
                    </div>
                    <Button disabled={disableGenButton} onClick={onOk} type='primary'>
                        <div>
                            <span>{t('re_gen')} </span><span className='ml-1'> (-{dataCost.removeObject}</span> Credits<span className='!ml-0'>)</span>

                        </div>  </Button>
                </div>
                <div className='left  pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw] min-h-3/4 flex justify-center items-center min-h-[75vh] ' onContextMenu={(e) => handleContextMenu(e)} onMouseDown={(e) => handleMouseDown(e)} onMouseMove={(e) => handleMouseMove(e)} onMouseUp={handleMouseUp}>
                    <div className="hidden">
                        <canvas id="layerTop"></canvas>
                    </div>
                    <canvas id="mycanvas" ref={canvasRef} ></canvas>
                    <div className={'absolute bottom-0 right-[13px] opacity-50'}>
                        <Radio.Group style={{ width: '100%' }} buttonStyle='solid' value={modeMove} onChange={(e) => onChangeSwitch(e.target.value)}>
                            <Radio.Button value="brush" ><HighlightOutlined /> </Radio.Button>
                            <Radio.Button value="move" ><DragOutlined /></Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            {/* </Modal> */}
        </div >
    )
}

export default ModalRemoveObject