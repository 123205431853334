import { Button, InputNumber, Radio, Slider, Switch, Tooltip } from 'antd';
import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    DragOutlined, HighlightOutlined, MinusOutlined, PlusOutlined, ThunderboltOutlined
} from '@ant-design/icons';

import idea from '../../assets/icons/bling.svg';
import logo from '../../assets/icons/logo-modeli.svg';
import { setLoadingApp } from '../../redux/app/loadingApp';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import FashionService from '../../services/fashion.service';
import {
    convertBase64Image, convertBase64ImageBlueToWhite, getDrawCursor, getImageSize, scaleImg, urlToBase64
} from '../../utils/function';
import NotifyController from '../../utils/toast';
import ModalRequiredSub from '../ModalRequiredSub/ModalRequiredSub';
import SpinFC from 'antd/es/spin';
import { useNavigate } from 'react-router-dom';
import { userSaga } from '../../redux/app/authSaga';
import { toolEditorImage } from '../../redux/app/toolEditor';
import Heading from '../../pages/Editor/DetailsTools/Heading';
import swal from 'sweetalert';
import NewModalRequiredSub from '../ModalRequiredSub/NewModalRequiredSub';
import { ModalSub } from '../../redux/app/appModalSub';
import AccountService from '../../services/account.service';

type ModalBorderSmoothProps = {
    url: string,
    samID: string,
    imageId: string,
    onClickSave: () => void,
    onClickBackToHome: () => void;
    setIsGenerating: Function;
    setIsOpenModal: Function;
    isModal: boolean;
};

function ModalBorderSmooth({
    url,
    samID,
    imageId,
    onClickSave,
    onClickBackToHome,
    setIsGenerating,
    setIsOpenModal,
    isModal

}: ModalBorderSmoothProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prevImageRef = useRef<fabric.Canvas | null>(null);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const coins = useAppSelector((store) => store.user.coins);
    const dataCost = useAppSelector(store => store.costFeature)
    const [specialId, setSpecialId] = useState('');
    const [disableGenButton, setDisableGenButton] = useState(false);
    const [isDraw, setIsDraw] = useState<boolean>(true);
    const [valueZoom, setValueZoom] = useState<any>(100);
    const [widthPoint, setWidthPoint] = useState<any>(10);
    const [widthPointByZoom, setWidthPointByZoom] = useState<any>(10);
    const [isDragging, setDragging] = useState(false);
    const [modeMove, setModeMove] = useState("brush");
    const [imageAutoLayer, setImageAutoLayer] = useState<
        | {
            imgMaskAuto: string;
            imgMaskedAuto: string;
        }
        | undefined
    >();
    const [ratio, setRatio] = useState<number>(1);
    const [isRightMouseDown, setRightMouseDown] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();
    const [ready, setReady] = useState(false);
    const [thickness, setThickness] = useState<number>(10);
    const OPACITY = 0.5;

    let isRedoing = false;

    const handleReset = () => {
        isRedoing = true
        if (fabricRef.current) {
            fabricRef.current._objects = []
            fabricRef.current && fabricRef.current.renderAll();
            setValueZoom(100)
            setWidthPoint(10)
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
    }

    function disablePanning() {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.off("mouse:move");
        }
    }

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.forEachObject(function (object) {
                object.selectable = true;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = false;
            });
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100);
        setValueZoom(e);
    };

    const inputOpacityPoint = (e: number) => {
        setWidthPoint(e);
    };

    const handleMinus = (e: any) => {
        const valueInput = widthPoint - 1;
        setWidthPoint(valueInput);
    };

    const handlePlus = (e: any) => {
        const valueInput = widthPoint + 1;
        setWidthPoint(valueInput);
    };

    let panning = false;


    const fillAll = async (arrayPath: any) => {
        try {
            dispatch(setLoadingApp.setLoading(true))
            // @ts-ignore
            fabricRef.current?.setBackgroundImage(null);
            fabricRef.current?.setBackgroundColor("black", () => { });
            fabricRef.current?.renderAll();
            const maskBase64 =
                fabricRef.current &&
                (fabricRef.current.toDataURL({
                    format: "png",
                    multiplier: multiplier
                }) as any);
            const finalMaskBase64 = await convertBase64ImageBlueToWhite(maskBase64, OPACITY);

            const data = await FashionService.getInstance().processBorderSmooth(
                {
                    initImage: url,
                    mask: finalMaskBase64,
                    id: imageId,
                }
            );

            setSpecialId(data);
            setDisableGenButton(false);
        } catch (error: any) {
            console.log(error)
            dispatch(setLoadingApp.setLoading(false))
            setDisableGenButton(false);
            swal(t(error.message), '', 'error')
        }
    };
    const updateUserCredits = async () => {
        try {
            const { coins } = await AccountService.getInstance().getUserInfo();
            dispatch(userSaga.setCredits(coins));
        } catch (error) {
            swal(t('something_wrong'), "", "error");
        }
    }
    const openModalSub = () => {
        dispatch(ModalSub.setIsOpenModalSub(true))
    }
    const onOke = async () => {
        if (coins < dataCost.borderSmooth) {
            openModalSub();
            NotifyController.warning(t("Not_enough_credit"));
        } else {
            if (fabricRef.current) {
                fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
                if (fabricRef.current._objects.length <= 0) {
                    NotifyController.warning(t("please_select_border"));
                } else {
                    if (ready) await fillAll(fabricRef.current._objects);
                }
            }
        }
    };

    const handleContextMenu = (event: any) => {
        if (event.button === 2) {
            disablePanning();
            setRightMouseDown(true);
            event.preventDefault();
        }
    };

    const handleMouseDown = (event: any) => {
        if (+event.button !== 2) return;
        if (isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = "grab";
            setDragging(true);
        }
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`;
            const delta = new fabric.Point(event.movementX, event.movementY);
            fabricRef.current.relativePan(delta);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && fabricRef.current) {
            setDragging(false);
            modeMove === "move" && setModeMove("brush");
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(
                widthPoint
            )}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`;
        }
        setRightMouseDown(false);
    };

    const onChangeType = (value: string) => {
        const checked = value === "move";
        setModeMove(value);
        if (fabricRef.current && checked) {
            fabricRef.current.selection = false;
            fabricRef.current.isDrawingMode = false;
            fabricRef.current.renderAll();
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = true;
            });

            let previousTouch: any;
            checked &&
                fabricRef.current.on("mouse:move", function (e: any) {
                    if (checked && panning && e && e.e && fabricRef.current) {
                        if (e.e.type === "mousemove") {
                            const delta = new fabric.Point(e.e.movementX, e.e.movementY);
                            fabricRef.current.relativePan(delta);
                        }
                        if (e.e.type === "touchmove" && panning) {
                            const touch = e.e?.touches[0];
                            if (previousTouch) {
                                const mX = touch.pageX - previousTouch?.pageX;
                                const mY = touch.pageY - previousTouch?.pageY;
                                const delta = new fabric.Point(mX, mY);
                                fabricRef.current.relativePan(delta);
                            }
                            previousTouch = touch;
                        }
                    }
                });
        }
        if (!checked && fabricRef.current) {
            disablePanning();
        }
    };

    const initialSetStateBG = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImg(width && width, height && height);

        if (imgScale) {
            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }
    }, [url]);

    const setBg = (canvasSizeData: any) => {
        return new Promise<void>((resolve) => {
            fabric.Image.fromURL(
                url,
                function (img: any) {
                    img.set({ erasable: false });
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: canvasSizeData.scaleX,
                                scaleY: canvasSizeData.scaleY,
                            }
                        );
                    ratio > 0 &&
                        fabricRef &&
                        fabricRef.current?.setHeight(
                            canvasSizeData.widthCanvas / ratio || 300
                        );
                    fabricRef &&
                        fabricRef.current?.setWidth(canvasSizeData.widthCanvas || 400);
                    resolve();
                },
                { crossOrigin: "*" }
            );
        })
    };


    const applyMaskToBG = (base64: string, sX: number, sY: number) => {
        if (base64 !== "") {
            // Remove the previous image if it exists
            if (prevImageRef.current) {
                fabricRef.current && fabricRef.current.remove(prevImageRef.current as any);
            }

            fabric.Image.fromURL(
                base64,
                function (img: any) {
                    img.set({
                        scaleX: sX,
                        scaleY: sY,
                        selectable: false,
                        opacity: OPACITY
                    });

                    // Save a reference to the current image
                    prevImageRef.current = img;

                    fabricRef.current && fabricRef.current.add(img);
                    fabricRef.current && fabricRef.current.renderAll();
                },
                { crossOrigin: "*" }
            );
        }
    };



    const handleSliderMouseUp = () => {
        setImageAutoLayer(undefined)
    };

    const handleAutoMode = async () => {
        if (!imageAutoLayer) {
            const newImageAutoLayer = await getAutoBorderMask(samID, thickness);
            setImageAutoLayer(newImageAutoLayer);
            applyMaskToBG(newImageAutoLayer?.imgMaskAuto!, canvasSizeData?.scaleX!, canvasSizeData?.scaleY!);
        }

        if (canvasSizeData && imageAutoLayer) {
            applyMaskToBG(imageAutoLayer.imgMaskAuto, canvasSizeData.scaleX, canvasSizeData.scaleY);
        }
    };

    const getAutoBorderMask = useCallback(async (samId: string, thickness: number) => {
        try {
            dispatch(setLoadingApp.setLoading(true));
            const body = { samSpecialId: samId, thickness };
            const dataAuto = await FashionService.getInstance().getBorderAuto(body);
            const processedImageBase64 = await convertBase64Image("data:image/png;base64," + dataAuto.data);
            if (processedImageBase64 && dataAuto) {
                dispatch(setLoadingApp.setLoading(false));
                return {
                    imgMaskAuto: processedImageBase64,
                    imgMaskedAuto: dataAuto.data as string,
                };
            }
            return imageAutoLayer;
        } catch (error) {
            dispatch(setLoadingApp.setLoading(false));
            return imageAutoLayer;
        }
    }, [imageAutoLayer, thickness]);

    const initFabric = useCallback(() => {
        // Check if the canvas element is available
        if (canvasRef.current) {
            // Initialize a new Fabric.js canvas with drawing mode enabled
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                isDrawingMode: true,
            });
            // Set up drawing cursor with a custom cursor image and crosshair fallback
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) 5 5, crosshair`;

            // Configure brush settings for free drawing
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";

            // Add event listener for the "object:added" event
            fabricRef.current.on("object:added", function () {
                // Clear history if not redoing
                // if (!isRedoing) {
                //     h = [];
                // }
                isRedoing = false;
            });

            // Add event listener for the "mouse:wheel" event (zooming)
            fabricRef.current.on("mouse:wheel", function (opt: any) {
                // Handle zooming based on mouse wheel movement
                if (fabricRef.current) {
                    const delta = opt.e.deltaY;
                    let zoom = fabricRef.current.getZoom();
                    zoom *= 0.999 ** delta;

                    // Set zoom constraints
                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;

                    // Get zoom center point and zoom to that point
                    const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                    fabricRef.current.zoomToPoint(zoomCenter, zoom);
                }
            });
        }
    }, [canvasRef, fabricRef, canvasSizeData, imageAutoLayer]);


    useEffect(() => {
        if (!ready) {
            (async () => {
                await initialSetStateBG();
                setReady(true);
            })();
        }
    }, [ready, initialSetStateBG]);

    useEffect(() => {
        let width = Math.round(widthPoint / (valueZoom / 100));
        if (width === 0) width = 1
        setWidthPointByZoom(width)
    }, [valueZoom, widthPoint])

    useEffect(() => {
        const handleWheel = (opt: any) => {
            if (fabricRef.current) {
                const delta = opt.e.deltaY;
                let zoom = fabricRef.current.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 3) zoom = 3;
                if (zoom < 1) zoom = 1;
                if (zoom === 1) {
                    setWidthPoint(10);
                }
                setValueZoom(zoom * 100);
                const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                fabricRef.current.zoomToPoint(zoomCenter, zoom);
            }
        };

        const handleModeOther = () => {
            // handleReset();
            if (fabricRef.current) {
                fabricRef.current.isDrawingMode = true;
                fabricRef.current.on('mouse:wheel', handleWheel);
            }
        };

        const initializeFabric = async () => {
            if (!fabricRef.current) {
                initFabric();
            }
        };

        const setBackground = async (canvasSizeData: any) => {
            if (canvasSizeData) {
                await setBg(canvasSizeData);
            }
        };

        if (ready) {
            (async () => {
                await initializeFabric();
                await setBackground(canvasSizeData);
                handleModeOther();
            })();
        }
    }, [ready, initFabric, canvasSizeData, imageAutoLayer]);

    useEffect(() => {
        (async () => {
            if (imageAutoLayer) {
                const newImageAutoLayer = await getAutoBorderMask(samID, thickness);
                setImageAutoLayer(newImageAutoLayer);
                applyMaskToBG(newImageAutoLayer?.imgMaskAuto!, canvasSizeData?.scaleX!, canvasSizeData?.scaleY!);
            }
        })()
    }, [thickness])
    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPointByZoom;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
    }, [widthPointByZoom])

    useEffect(() => {
        if (fabricRef.current) {
            if (isDraw) {
                fabricRef.current.freeDrawingBrush = new fabric.PencilBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
                fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";
            } else {
                // @ts-ignore
                fabricRef.current.freeDrawingBrush = new fabric.EraserBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
            }
        }
    }, [isDraw])

    let interval: any;
    useEffect(() => {
        if (specialId) {
            interval = setInterval(async () => {
                const listeningImage = await FashionService.getInstance().listenImageRemove(specialId);
                if (listeningImage.status === 'FINISHED') {
                    updateUserCredits()
                    if (interval) {
                        clearInterval(interval);
                    }
                    const urlBase64 = await urlToBase64(listeningImage.url_arr[0] + '?not-from-cache-please');
                    const imageUrl = 'data:image/png;base64,' + urlBase64;
                    dispatch(toolEditorImage.setImageInput(imageUrl));
                    dispatch(setLoadingApp.setLoading(false))
                    setReady(false);
                    handleReset();
                }
                if (listeningImage.status === 'FAILED') {
                    swal(t('something_wrong'), "", "error");
                    updateUserCredits()
                    if (interval) {
                        clearInterval(interval);
                    }
                    handleReset();
                    dispatch(setLoadingApp.setLoading(false))
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [specialId])
    return (
        <div className='flex flex-col gap-2'>

            {/* <Modal
                open={true}
                onCancel={() => closeModal()}
                width={"fit-content"}
                title={t("border_smooth")}
                footer={null}
            > */}
            <Heading title={t('border_smooth')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            <div className="flex gap-2 flex-row sm:flex-col  md:flex-col lg:flex-col w-full justify-between  md:items-center sm:items-center lg:items-center ">
                <div className="flex flex-col gap-2 ml-10">
                    <div className='sub-title text-base font-semibold text-left'>{t('action')}</div>
                    <div className='flex flex-col gap-2'>
                        <div className='text-left' >
                            <div>{t('thickness')}</div>
                        </div>
                        <div style={{ width: '100%' }} onMouseUp={handleSliderMouseUp}>
                            <Slider
                                defaultValue={thickness}
                                min={7}
                                max={30}
                                onChange={(value) => setThickness(value)}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 pb-4' >
                        <div className='font-semibold text-left'>
                            {t('get_mark_with_ai')}
                        </div>
                        <Tooltip title={t('auto_mask_tooltip')}>
                            <Button onClick={handleAutoMode} type="default" style={{
                                borderColor: '#0047FF',
                                color: '#0047FF'
                            }} className='flex justify-center items-center gap-3'>{t('auto_border')} <img src={idea} alt="" /></Button>
                        </Tooltip>
                    </div>
                    <div className="flex justify-center items-center flex-col">
                        <div className="flex flex-col gap-2 w-full">
                            <div className="flex gap-2 flex-col">
                                <div className='flex flex-col gap-2'>
                                    <div className='text-left'>{t('zoom_image')}:</div>
                                    <div className="flex gap-2 justify-center items-center">
                                        <div>100%</div>
                                        <Slider
                                            min={100}
                                            max={300}
                                            defaultValue={100}
                                            value={valueZoom}
                                            onChange={onChangeZoomPlus}
                                            className="min-w-[210px]"
                                        />
                                        <div>300%</div>
                                    </div>
                                </div>
                                <div className='flex flex-col  gap-2'>
                                    <div className='text-left'>
                                        {t('size_brush')}:
                                    </div>
                                    <div className="flex gap-2 justify-center items-center">
                                        <Button
                                            shape="circle"
                                            type="default"
                                            icon={<MinusOutlined />}
                                            onClick={(e) => handleMinus(e)}
                                        />
                                        <Slider
                                            min={1}
                                            max={100}
                                            value={widthPoint}
                                            onChange={(e) => inputOpacityPoint(e)}
                                            className="min-w-[210px]"
                                        />
                                        <Button
                                            shape="circle"
                                            type="default"
                                            icon={<PlusOutlined />}
                                            onClick={(e) => handlePlus(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button disabled={disableGenButton} type="primary" className='w-full mt-2' onClick={onOke}>
                            {t("confirm") + `(-${dataCost.borderSmooth} `}
                            Credits
                            {")"}
                        </Button>
                    </div>
                    <div className='sub-title text-base font-semibold text-left mt-2'>{t('guide')}</div>
                    <div className="tip flex gap-2 rounded-lg max-w-[400px] p-3 text-left box-guide-tool">
                        <div className="text-[#101828] text-sm">
                            {t("smooth_guide")}
                        </div>
                    </div>
                </div >
                <div
                    className="border-smooth-left relative bg-[#CCD9FF] !w-[65vw] max-w-full  h-full  flex justify-center items-center  min-h-[75vh]"
                    onContextMenu={(e) => handleContextMenu(e)}
                    onMouseDown={(e) => handleMouseDown(e)}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseUp={handleMouseUp}
                >
                    <canvas
                        id="mycanvas"
                        ref={canvasRef}
                    ></canvas>
                    <div className={"absolute bottom-0 right-0 opacity-50"}>
                        <Radio.Group
                            style={{ width: "100%" }}
                            buttonStyle="solid"
                            value={modeMove}
                            onChange={(e) => onChangeType(e.target.value)}
                        >
                            <Radio.Button value="brush">
                                <HighlightOutlined />{" "}
                            </Radio.Button>
                            <Radio.Button value="move">
                                <DragOutlined />
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className={"absolute mx-1 bottom-0 left-0 opacity-50"}>
                        <Switch onChange={(value: any) => setIsDraw(value)} checkedChildren="Draw" unCheckedChildren="Erase" defaultChecked />
                    </div>
                </div>
            </div >
            {/* </Modal> */}
        </div >
    );
}

export default ModalBorderSmooth;